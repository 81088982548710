@tailwind base;
@tailwind components;
@tailwind utilities;

.textjustify {
  text-align-last: start;
  text-align: justify;
}

.bannerh {
  height: 36rem;
}
